<template>
  <ValidationObserver ref="observer">
    <b-row class="text-left">
      <b-col
        md="12"
      >
        <validation-provider
          #default="{ errors }"
          name="Rangkuman Hasil Pembahasan"
          rules="required"
        >
          <b-form-textarea
            id="vi-rangkuman"
            v-model="rangkuman"
            rows="4"
            class="mb-1"
            required
            name="rangkuman"
            placeholder="Rangkuman Hasil Pembahasan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <b-form-group>
          <b-form-radio-group
            v-model="mode"
            :options="options"
            button-variant="outline-secondary"
            class="rekomendasi-kegiatan-radios"
            size="lg"
            name="rekomendasi"
            buttons
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="mode == 'yes'"
        cols="12"
        class="mb-1"
      >
        <b-list-group flush>
          <validation-provider
            #default="{ errors }"
            name="Rekomendasi"
            rules="required"
          >
            <b-list-group-item
              v-for="(data, index) in daftarRekoLanjut"
              :key="index"
              class="d-flex justify-content-between"
            >
              <b-form-checkbox
                :ref="`reko-${stepRekomendasi}-${data.rekomendasi_id}`"
                v-model="selectLanjut"
                :value="data.rekomendasi_id"
                plain
              >
                {{ data.rekomendasi }}
              </b-form-checkbox>
              <b-button
                variant="flat-secondary"
                class=""
                size="sm"
                @click="copyReko(data.rekomendasi)"
              >
                <feather-icon
                  icon="CopyIcon"
                  size="10"
                />
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-form-group
                label="Rekomendasi Tambahan"
                label-for="reko_description"
              >
                <b-form-textarea
                  id="vi-reko_description"
                  v-model="reko_description"
                  rows="5"
                  name="reko_description"
                  placeholder="Rekomendasi Tambahan"
                />
              </b-form-group>
            </b-list-group-item>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-list-group>
      </b-col>
      <b-col
        v-else-if="mode == 'no'"
        cols="12"
        class="mb-1"
      >
        <b-list-group flush>
          <validation-provider
            #default="{ errors }"
            name="Rekomendasi"
            rules="required"
          >
            <b-list-group-item
              v-for="(data, index) in daftarRekoTidak"
              :key="index"
              class="d-flex justify-content-between"
            >
              <b-form-checkbox
                :ref="`reko-${stepRekomendasi}-${data.rekomendasi_id}`"
                v-model="selectTidak"
                :value="data.rekomendasi_id"
                plain
              >
                {{ data.rekomendasi }}
              </b-form-checkbox>
              <b-button
                variant="flat-secondary"
                class=""
                size="sm"
                @click="copyReko(data.rekomendasi)"
              >
                <feather-icon
                  icon="CopyIcon"
                  size="10"
                />
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-form-group
                label="Rekomendasi Tambahan"
                label-for="reko_description"
              >
                <b-form-textarea
                  id="vi-reko_description"
                  v-model="reko_description"
                  rows="5"
                  name="reko_description"
                  placeholder="Rekomendasi Tambahan"
                />
              </b-form-group>
            </b-list-group-item>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-list-group>
      </b-col>

      <b-col md="12">
        <b-form-group
          label="Dokumen Pendukung"
          label-for="docs"
        >
          <b-form-file
            ref="docs"
            v-model="file2"
            name="docs"
            class="mt-1"
            required
            @input="checkFile"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="errorStat"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <ul v-if="typeof errorMsg === 'object'">
              <li
                v-for="(item, index) in errorMsg"
                :key="index"
              >
                {{ index }}:
                <span
                  v-for="(child, indexs) in item"
                  :key="indexs"
                >
                  {{ child }}
                </span>
              </li>
            </ul>
            <span v-else>{{ errorMsg }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="mode"
      >
        <b-button
          variant="outline-success"
          class="mt-0 mt-md-1"
          size="sm"
          @click="updateSave"
        >
          <span>Simpan Rekomendasi</span>
        </b-button>
        <b-button
          v-if="dataSaved"
          variant="flat-success"
          size="sm"
          class="mt-0 mt-md-2 ml-50"
        >
          <feather-icon
            icon="CheckCircleIcon"
            size="14"
          /> Rekomendasi Berhasil Disimpan
        </b-button>
      </b-col>
    </b-row>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormRadioGroup, BFormGroup, BFormFile, BListGroup, BListGroupItem, BRow, BCol,
  BFormCheckbox, BFormTextarea, BButton, BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    detailProgram: {
      type: Object,
      default: () => {},
    },
    stepRekomendasi: {
      type: String,
      default: 'default',
    },
    componentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      dataSaved: false,
      targetUrl: '',
      mode: '',
      selectLanjut: [],
      selectTidak: [],
      daftarRekoLanjut: [],
      daftarRekoTidak: [],
      rangkuman: '',
      reko_description: '',
      options: [
        { text: 'Dilanjutkan', value: 'yes', variant: 'text-info' },
        { text: 'Tidak Dilanjutkan', value: 'no', variant: 'text-danger' },
      ],
      file2: null,
      errorStat: false,
      errorMsg: '',
    }
  },
  mounted() {
    this.getTemplateReko()
  },
  methods: {
    copyReko(id) {
      navigator.clipboard.writeText(id)
    },
    checkFile(input) {
      if (input.size > 100 * 1024 * 1024) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Ukuran Dokumen Terlalu Besar`,
        }
      } else if (input.type !== 'application/pdf') {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Jenis Dokumen Tidak Sesuai`,
        }
      } else {
        this.alert = false
      }
    },
    getTemplateReko() {
      if (this.stepRekomendasi !== 'default') {
        if (this.stepRekomendasi === 'aplikasi') {
          this.targetUrl = 'application-data'
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          this.targetUrl = 'infrastructure-data'
        }
        if (this.stepRekomendasi === 'non-teknis') {
          this.title = 'Non-Teknis'
          this.targetUrl = 'non-teknis-data'
        }
        if (this.stepRekomendasi === 'datainformasi') {
          this.title = 'Data dan Informasi'
          this.targetUrl = 'information-data'
        }

        const params = {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.detailProgram.data_utama_id,
        }

        if (this.stepRekomendasi === 'aplikasi') {
          params.aplikasi_id = this.detailProgram.aplikasi_id
          params.type = 'app'
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          params.infrastruktur_id = this.detailProgram.infrastruktur_id
          params.type = 'infra'
        }
        if (this.stepRekomendasi === 'non-teknis') {
          params.non_teknis_id = this.detailProgram.non_teknis_id
          params.type = 'non-teknis'
        }
        if (this.stepRekomendasi === 'datainformasi') {
          params.data_informasi_id = this.detailProgram.data_informasi_id
          params.type = 'data'
        }

        const promise1 = new Promise(resolve => {
          this.$http.get(`/recommendation/${this.targetUrl}/references?token=${localStorage.getItem('userToken')}`)
            .then(res => {
              if (res.data.status === 'success') {
                this.daftarRekoLanjut = []
                this.daftarRekoTidak = []
                res.data.data.ref_recommendations.map(value => {
                  if (value.tipe === 'CONTINUE') {
                    const reko = {
                      rekomendasi_id: value.rekomendasi_id,
                      rekomendasi: value.rekomendasi,
                    }
                    this.daftarRekoLanjut.push(reko)
                  } else if (value.tipe === 'PENDING') {
                    const reko = {
                      rekomendasi_id: value.rekomendasi_id,
                      rekomendasi: value.rekomendasi,
                    }
                    this.daftarRekoTidak.push(reko)
                  }
                  return true
                })
                resolve(true)
              }
            })
        })

        const promise2 = new Promise(resolve => {
          this.$http.get('/recommendation/get-rekomendasi-sementara', {
            params,
          })
            .then(res => {
              if (res.data.status === 'success') {
                this.selectLanjut = []
                this.selectTidak = []
                res.data.data.continue.map(value => {
                  if (this.selectLanjut.indexOf(value.id) === -1) {
                    this.selectLanjut.push(value.id)
                  }
                  return true
                })
                res.data.data.postpone.map(value => {
                  if (this.selectTidak.indexOf(value.id) === -1) {
                    this.selectTidak.push(value.id)
                  }
                  return true
                })
                resolve(true)
              }
            })
        })

        Promise.all([promise1, promise2]).then(values => {
          if (values[0] && values[1]) {
            this.show = true
          }
          return values
        })
      }
    },
    updateSave() {
      const check = (this.mode === 'yes') ? this.daftarRekoLanjut : this.daftarRekoTidak
      const verif = {
        data_utama_id: this.detailProgram.data_utama_id,
        domain: this.stepRekomendasi.toUpperCase(),
        tipe_rekomendasi: (this.mode === 'yes') ? 1 : 0,
        rekomendasi_id: (this.mode === 'yes') ? this.selectLanjut : this.selectTidak,
        keterangan: this.rangkuman,
      }
      if (this.stepRekomendasi === 'aplikasi') {
        verif.domain = 'APLIKASI'
      }
      if (this.stepRekomendasi === 'infrastruktur') {
        verif.domain = 'INFRASTRUKTUR'
      }
      if (this.stepRekomendasi === 'non-teknis') {
        verif.domain = 'NON-TEKNIS'
      }
      if (this.stepRekomendasi === 'datainformasi') {
        verif.domain = 'DATA'
      }
      if (check.length !== 0 && verif.rekomendasi_id.length === 0) {
        this.errorStat = true
        this.errorMsg = 'Rekomendasi Belum Dipilih'
      } else {
        if (this.reko_description !== '') {
          verif.rekomendasi_tambahan = this.reko_description
        }
        if (this.stepRekomendasi === 'aplikasi') {
          verif.aplikasi_id = this.detailProgram.aplikasi_id
        }
        if (this.stepRekomendasi === 'infrastruktur') {
          verif.infrastruktur_id = this.detailProgram.infrastruktur_id
        }
        if (this.stepRekomendasi === 'non-teknis') {
          verif.non_teknis_id = this.detailProgram.non_teknis_id
        }
        if (this.stepRekomendasi === 'datainformasi') {
          verif.data_informasi_id = this.detailProgram.data_informasi_id
        }
        this.sendRekomendasi(verif)
      }
    },
    sendRekomendasi(verif) {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/pk/add-result?token=${localStorage.getItem('userToken')}`, verif)
        .then(res => {
          if (res.data) {
            const status = true
            const promise2 = new Promise(resolve => {
              if (this.file2) {
                const formFile = new FormData()
                formFile.append('additional_file', this.file2)

                if (this.stepRekomendasi === 'aplikasi') {
                  formFile.append('app_infra', 'aplikasi')
                }
                if (this.stepRekomendasi === 'infrastruktur') {
                  formFile.append('app_infra', 'infra')
                }

                this.$http.post(`pk/pk-upload/${res.data.data.peninjauan_kembali_id}?token=${localStorage.getItem('userToken')}`, formFile, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      this.file2 = null
                      resolve(true)
                    } else {
                      document.getElementById('loading-bg').style.display = 'none'
                      this.alert = true
                      this.alertDetail = {
                        variant: 'danger',
                        title: 'Error Found',
                        text: resp.data.error,
                      }
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    document.getElementById('loading-bg').style.display = 'none'
                    this.alert = true
                    this.alertDetail = {
                      variant: 'danger',
                      title: 'Error Found',
                      text: error.response.data.error,
                    }
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            Promise.all([promise2, status]).then(values => {
              if (values[0] && values[1]) {
                document.getElementById('loading-bg').style.display = 'none'
                this.dataSaved = true

                this.getTemplateReko()

                setTimeout(() => {
                  this.dataSaved = false
                  this.$router.replace('/konfirmasi-rekomendasi')
                }, 2000)
              }
            })
              .catch(error => {
                document.getElementById('loading-bg').style.display = 'none'
                this.alert = true
                this.alertDetail = {
                  variant: 'danger',
                  title: 'Error Found',
                  text: error.response.data.error,
                }
              })
          }
          return res.data.status
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
