<template>
  <div>
    <b-row
      class="pt-1"
    >
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>DATA POKOK</h5>
        <p>{{ dataDoc.data_pokok }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>DATA TEMATIK</h5>
        <p>{{ dataDoc.data_tematik }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>DATA TOPIK</h5>
        <p>{{ dataDoc.data_topic }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>DATA SUB TOPIK</h5>
        <p>{{ dataDoc.sub_data_topic }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA DATA</h5>
        <p>{{ dataDoc.nama }}</p>
      </b-col>
      <b-col md="3">
        <h5>INTEROPERABILITAS</h5>
        <p>
          <b-badge>
            {{ (!dataDoc.interoperability) ? (dataDoc.interoperability_id !== 0 ) ? 'Ya' : 'Tidak' : dataDoc.interoperability }}
          </b-badge>
        </p>
      </b-col>
      <b-col md="3">
        <h5>SIFAT DATA</h5>
        <p>{{ dataDoc.sifat_data }}</p>
      </b-col>
      <b-col md="12">
        <h5>TUJUAN DATA</h5>
        <p>{{ dataDoc.tujuan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>VALIDITAS DATA</h5>
        <p>{{ dataDoc.valid_start | formatDate }} &dash; {{ dataDoc.valid_end | formatDate }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>PENANGGUNG JAWAB</h5>
        <p>{{ dataDoc.pic_name }}</p>
      </b-col>
      <b-col md="12">
        <h5>URAIAN DATA</h5>
        <p>{{ dataDoc.deskripsi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>WALI DATA</h5>
        <p>{{ dataDoc.walidata }}</p>
      </b-col>
    </b-row>
    <template v-if="dataDoc.dokumen_rujukan">
      <b-row>
        <b-col class="mb-1">
          <h5>Dokumen Rujukan</h5>
          <b-list-group flush>
            <b-list-group-item
              v-for="(key, idx) in dataDoc.dokumen_rujukan"
              :key="idx"
            >
              <template v-if="key.dokumen_rujukan_id === 1">
                <p class="mb-25">
                  <b>Nama Regulasi</b>: {{ key.keterangan }}
                </p>
                <p class="mb-25">
                  <b-button
                    variant="success"
                    size="sm"
                    @click="viewFile(key.document_uri)"
                  >
                    <feather-icon
                      icon="BookOpenIcon"
                      size="14"
                    /></b-button>
                  <b-link
                    :href="getDoc(key.document_uri)"
                    class="btn btn-sm btn-info mx-25"
                    target="_blank"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                    /></b-link>
                  <small>{{ key.document_uri.substring(key.document_uri.lastIndexOf('/') + 1) }}</small>
                </p>
              </template>
              <template v-else>
                <b>{{ key.nama_dokumen }}</b> {{ (key.nama) ? ' : ' + key.nama : '' }}
              </template>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </template>
    <template v-if="dataDoc.jenis_data && dataDoc.jenis_data.length !== 0">
      <template
        v-for="(jenis, idx) in dataDoc.jenis_data"
      >
        <b-row
          :key="jenis.jenis_data"
          class="border-top py-1"
        >
          <b-col
            cols="12"
          >
            <h5 class="mb-1 pb-1 border-bottom">
              {{ jenis.jenis_data }}
            </h5>
          </b-col>
          <b-col
            v-if="jenis.kategori_jenis_data"
            md="12"
            class="mb-50"
          >
            <h5>KATEGORI Belanja {{ jenis.jenis_data }}</h5>
            <p>{{ jenis.kategori_jenis_data }}</p>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <h5>PRODUSEN {{ jenis.jenis_data }}</h5>
            <p>{{ jenis.produsen_data }}</p>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <h5>Aplikasi Pendukung/Pengumpulan Data</h5>
            <p>{{ (jenis.aplikasi_sumber) ? jenis.aplikasi_sumber : '-' }}</p>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <h5>SIFAT DATA</h5>
            <p>{{ jenis.sifat_data }}</p>
          </b-col>
          <b-col
            v-if="jenis.total_anggaran"
            md="6"
            class="mb-50"
          >
            <h5>TOTAL ANGGARAN</h5>
            <p>{{ jenis.total_anggaran ? Number(jenis.total_anggaran).toLocaleString() : 0 }}</p>
          </b-col>
          <b-col
            v-if="jenis.kak_filename"
            md="6"
            class="mb-50"
          >
            <h5>Kerangka Acuan Kerja</h5>
            <p>
              <b-button
                variant="success"
                size="sm"
                class="mr-25 mb-25"
                @click="viewFile(jenis.document_kak)"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  size="14"
                /></b-button>
              <b-link
                :href="getDoc(jenis.document_kak)"
                class="btn btn-sm btn-info mr-25 mb-25"
                target="_blank"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="14"
                /></b-link>
              <small>{{ jenis.document_kak.substring(jenis.document_kak.lastIndexOf('/') + 1) }}</small>
            </p>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <h5>Daftar Data {{ (idx === 1) ? '/ Surat Rekomendasi' : '' }}</h5>
            <p>
              <b-link
                :href="getDoc(jenis.document_uri)"
                class="btn btn-sm btn-info mr-25 mb-25"
                target="_blank"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="14"
                /></b-link>
              <small>{{ jenis.document_uri.substring(jenis.document_uri.lastIndexOf('/') + 1) }}</small>
            </p>
          </b-col>
          <b-col
            v-if="jenis.formulir_filename"
            md="6"
            class="mb-50"
          >
            <h5>Formulir Rekomendasi</h5>
            <p>
              <b-button
                variant="success"
                size="sm"
                class="mr-25 mb-25"
                @click="viewFile(jenis.document_formulir)"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  size="14"
                /></b-button>
              <b-link
                :href="getDoc(jenis.document_formulir)"
                class="btn btn-sm btn-info mr-25 mb-25"
                target="_blank"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="14"
                /></b-link>
              <small>{{ jenis.document_formulir.substring(jenis.document_formulir.lastIndexOf('/') + 1) }}</small>
            </p>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BButton, BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BLink,
    BButton,
    BBadge,
    BListGroup,
    BListGroupItem,
  },
  props: {
    dataDoc: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    viewFile(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
  },
}
</script>
