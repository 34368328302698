<template>
  <div>
    <b-row class="pt-1 border-top-dark">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>NAMA ITEM PENGADAAN</h5>
        <p>{{ dataDoc.nama }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TOTAL ANGGARAN</h5>
        <p>{{ dataDoc.total_anggaran ? Number(dataDoc.total_anggaran).toLocaleString() : 0 }}</p>
      </b-col>
      <b-col
        v-if="dataDoc.perangkat_pendukung && dataDoc.perangkat_pendukung.length"
        md="12"
      >
        <h5>ITEM PENDUKUNG</h5>
        <ol>
          <li
            v-for="(pp, idx) in dataDoc.perangkat_pendukung"
            :key="idx"
          >
            {{ pp }}
          </li>
        </ol>
      </b-col>
      <b-col md="12">
        <h5>URAIAN ITEM PENGADAAN</h5>
        <p>{{ dataDoc.uraian }}</p>
      </b-col>
      <b-col
        v-if="dataDoc.file_kak !== null"
        md="6"
        class="mb-50"
      >
        <h5>Kerangka Acuan Kerja</h5>
        <p>
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(dataDoc.kak_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(dataDoc.kak_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <small>{{ dataDoc.kak_uri }}</small>
        </p>
      </b-col>
      <b-col
        v-if="dataDoc.additional_docs && dataDoc.additional_docs.length !== 0"
        md="6"
        class="mb-50"
      >
        <h5>Dokumen Tambahan</h5>
        <p
          v-for="(data, index) in dataDoc.additional_docs"
          :id="`dt-${index}`"
          :key="index"
        >
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(data.document_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(data.document_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            />
          </b-link>
          <small>{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}</small>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BLink,
  },
  props: {
    dataDoc: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    viewFile(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
  },
}
</script>
