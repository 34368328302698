<template>
  <b-row>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jenis Perangkat Lunak</h5>
      <p>{{ infData.jenis }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jenis {{ infData.jenis }}</h5>
      <p>{{ infData.perangkat_lunak }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jenis Lisensi</h5>
      <p>{{ infData.lisensi_infrastruktur }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Nama Pemilik Lisensi</h5>
      <p>{{ infData.pemilik_lisensi }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Validitas Lisensi Perangkat Lunak</h5>
      <p>{{ infData.validasi_lisensi }}</p>
    </b-col>
    <b-col
      md="12"
      class="mb-50"
    >
      <h5>Deskripsi Perangkat Lunak</h5>
      <p>{{ infData.deskripsi }}</p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    infData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
