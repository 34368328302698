<template>
  <b-row>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jenis Sistem Penghubung</h5>
      <p>{{ infData.jenis }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Kepemilikan</h5>
      <p>{{ infData.kepemilikan }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Nama Pemilik</h5>
      <p>{{ infData.nama_pemilik }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Nama Jaringan Intra yang digunakan</h5>
      <p>{{ infData.nama_jaringan }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Aplikasi yang dihubungkan</h5>
      <p>{{ infData.aplikasi_terhubung }}</p>
    </b-col>
    <b-col
      md="12"
      class="mb-50"
    >
      <h5>Deskripsi Sistem Penghubung</h5>
      <p>{{ infData.deskripsi }}</p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    infData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
