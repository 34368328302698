<template>
  <b-row
    v-if="show"
    class="text-left"
  >
    <b-col
      md="12"
    >
      <h3 class="mb-1">
        {{ (rangkuman.is_agreed) ? 'Setuju' : 'Tidak Setuju' }}
      </h3>
      <b-card-body class="p-1 mb-1 border-bottom">
        <h5>Penjelasan Kegiatan</h5>
        <p v-html="rangkuman.tanggapan" />
      </b-card-body>

      <b-card-body class="p-1">
        <h5>Penjelasan Teknis</h5>
        <p
          v-if="rangkuman.spesifikasi_teknis"
          v-html="rangkuman.spesifikasi_teknis"
        />
        <span v-else>&mdash;</span>
      </b-card-body>

      <template
        v-if="rangkuman.document"
      >
        <h5>Dokumen Pendukung</h5>
        <p>
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="openKomponen(rangkuman.document)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(rangkuman.document)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <small>{{ rangkuman.document.substring(rangkuman.document.lastIndexOf('/') + 1) }}</small>
        </p>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BLink, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BCardBody,
  },
  props: {
    detailProgram: {
      type: Object,
      default: () => {},
    },
    stepRekomendasi: {
      type: String,
      default: 'default',
    },
    componentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      rangkuman: {},
      options: [
        { text: 'Dilanjutkan', value: 'yes', variant: 'text-info' },
        { text: 'Tidak Dilanjutkan', value: 'no', variant: 'text-danger' },
      ],
      errorStat: false,
      errorMsg: '',
    }
  },
  mounted() {
    this.getTemplateReko()
  },
  methods: {
    copyReko(id) {
      navigator.clipboard.writeText(id)
    },
    openKomponen(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    getTemplateReko() {
      if (this.stepRekomendasi !== 'default') {
        const params = {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.detailProgram.data_utama_id,
          type: this.stepRekomendasi,
        }
        if (this.stepRekomendasi === 'app' || this.stepRekomendasi === 'aplikasi') {
          params.type = 'app'
          params.aplikasi_id = this.detailProgram.aplikasi_id
        } else if (this.stepRekomendasi === 'infra' || this.stepRekomendasi === 'infrastruktur') {
          params.type = 'infra'
          params.infrastruktur_id = this.detailProgram.infrastruktur_id
        } else if (this.stepRekomendasi === 'data') {
          params.type = 'data'
          params.data_informasi_id = this.detailProgram.data_informasi_id
        } else if (this.stepRekomendasi === 'non-teknis') {
          params.type = 'non-teknis'
          params.non_teknis_id = this.detailProgram.non_teknis_id
        }

        this.$http.get('/recommendation/get-tanggapan-ippd', {
          params,
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.rangkuman = res.data.data

              const tanggapan1 = this.rangkuman.tanggapan.split('\n')
              this.rangkuman.tanggapan = tanggapan1.join('<br>')

              const tanggapan2 = this.rangkuman.spesifikasi_teknis.split('\n')
              this.rangkuman.spesifikasi_teknis = tanggapan2.join('<br>')
            }
          })

        this.show = true
      }
    },
  },
}
</script>
