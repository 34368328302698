<template>
  <section id="detail-kegiatan">
    <b-row
      v-if="app_view"
    >
      <b-col
        lg="3"
        cols="12"
      >
        <b-card class="shadow-none">
          <!-- about -->
          <h2 class="text-center mb-2">
            {{ dataProgram.nama_kegiatan }}
          </h2>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ClipboardIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>No. Tiket</small>
              <h4 class="mb-0">
                {{ dataProgram.uuid }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="UserIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Penanggung Jawab</small>
              <h4 class="mb-0">
                {{ dataProgram.pic }} ({{ dataProgram.pic_number }})
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Tahun Anggaran</small>
              <h4 class="mb-0">
                {{ dataProgram.tahun_anggaran }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ShoppingBagIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Total Anggaran</small>
              <h4 class="mb-0">
                {{ Number(dataProgram.total_anggaran).toLocaleString() }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="BookOpenIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Kode Mata Anggaran</small>
              <h4 class="mb-0">
                {{ dataProgram.kode_anggaran }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ShoppingCartIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Jenis Belanja</small>
              <h4 class="mb-0">
                {{ dataProgram.jenis_belanja }}
                <feather-icon
                  v-if="dataProgram.prioritas_nasional === 1"
                  icon="StarIcon"
                  size="18"
                />
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ListIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Jenis Pengadaan</small>
              <h4 class="mb-0">
                {{ dataProgram.jenis_pengadaan }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="BriefcaseIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Sumber Dana</small>
              <h4 class="mb-0">
                {{ dataProgram.sumber_dana }}
              </h4>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <b-card
          :header="title"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <model-aplikasi
            v-if="jenis_pengadaan_id === 3"
            :data-aplikasi="dataItem"
            @view-file="viewFile"
          />
          <model-data
            v-if="jenis_pengadaan_id === 6"
            :data-doc="dataItem"
            @view-file="viewFile"
          />
          <model-infra
            v-if="jenis_pengadaan_id === 2"
            :data-infra="dataItem"
            @view-file="viewFile"
          />
          <model-non-teknis
            v-if="jenis_pengadaan_id === 7"
            :data-doc="dataItem"
            @view-file="viewFile"
          />
        </b-card>

        <b-card
          header="Rekapitulasi Rekomendasi Sementara"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <summary-rekomendasi
            :rekap-lanjut="listRekomendasi.continue"
            :rekap-tidak="listRekomendasi.postpone"
          />
        </b-card>

        <b-card
          v-if="view_type === 'ask'"
          header="Apakah anda setuju dengan rekomendasi di atas?"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <modul-tanya-rekomendasi
            :detail-data="dataItem"
            :component-type="type"
          />
        </b-card>

        <template
          v-else-if="view_type === 'konfirmasi'"
        >
          <b-card
            header="Tanggapan K/L"
            header-tag="h4"
            header-class="border-bottom mb-2"
          >
            <summary-tanggapan
              :component-id="Number(item_id)"
              :detail-program="dataItem"
              :step-rekomendasi="step"
              @view-file="viewFile"
            />
          </b-card>

          <b-card
            header="Hasil Pembahasan Peninjauan Kembali"
            header-tag="h4"
            header-class="border-bottom mb-2"
          >
            <modul-rekomendasi-konfirmasi
              :component-id="Number(item_id)"
              :detail-program="dataItem"
              :step-rekomendasi="type"
            />
          </b-card>
        </template>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BCardText, BRow, BCol, BCard, BModal, BAvatar,
} from 'bootstrap-vue'
import VuePdfApp from 'vue-pdf-app'

import SummaryRekomendasi from './SummaryRekomendasi.vue'
import SummaryTanggapan from './SummaryTanggapan.vue'
import ModulRekomendasiKonfirmasi from './ModulRekomendasiKonfirmasi.vue'
import ModulTanyaRekomendasi from './ModulTanyaRekomendasi.vue'

import ModelAplikasi from '../kegiatan/detail/DetailAplikasi.vue'
import ModelData from '../kegiatan/detail/DetailDataInformasi.vue'
import ModelInfra from '../kegiatan/detail-kegiatan/KegiatanInfrastruktur.vue'
import ModelNonTeknis from '../kegiatan/detail/DetailNonTeknis.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BModal,
    BCardText,

    SummaryRekomendasi,
    SummaryTanggapan,
    ModulTanyaRekomendasi,
    ModulRekomendasiKonfirmasi,
    ModelAplikasi,
    ModelData,
    ModelInfra,
    ModelNonTeknis,
    VuePdfApp,
  },
  data() {
    return {
      data_id: '',
      item_id: '',
      jenis_pengadaan_id: '',
      title: '',
      targetUrl: '',
      type: '',
      step: '',
      app_view: false,
      view_type: '',
      fileDoc: '',
      rangkuman: '',
      dataProgram: {},
      dataItem: {},
      listRekomendasi: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  created() {
    this.data_id = this.$route.params.utama
    this.jenis_pengadaan_id = Number(this.$route.params.id)
    this.view_type = this.$route.params.type

    if (this.$route.params.data) {
      this.item_id = this.$route.params.data
    }

    if (this.jenis_pengadaan_id === 3) {
      this.title = 'Aplikasi'
      this.type = 'aplikasi'
      this.step = 'app'
      this.targetUrl = 'applications'
    } else if (this.jenis_pengadaan_id === 2) {
      this.title = 'Infrastruktur'
      this.type = 'infrastruktur'
      this.step = 'infra'
      this.targetUrl = 'infrastruktur'
    } else if (this.jenis_pengadaan_id === 7) {
      this.title = 'Non-Teknis'
      this.type = 'non-teknis'
      this.step = 'non-teknis'
      this.targetUrl = 'non-teknis'
    } else if (this.jenis_pengadaan_id === 6) {
      this.title = 'Data dan Informasi'
      this.type = 'datainformasi'
      this.step = 'data'
      this.targetUrl = 'data-informasi'
    }
  },
  mounted() {
    this.getProgram()
  },
  methods: {
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    getProgram() {
      this.$http.get('/clearance/core-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.$route.params.utama,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataProgram = res.data.data
            if (this.dataProgram) {
              this.setItem()
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    setItem() {
      const params = {
        token: localStorage.getItem('userToken'),
        clearance_id: this.dataProgram.clearance_id,
        data_utama_id: this.$route.params.utama,
      }
      if (this.item_id) {
        if (this.type === 'aplikasi') {
          params.aplikasi_id = this.item_id
        } else if (this.type === 'infrastruktur') {
          params.infrastruktur_id = this.item_id
        } else if (this.type === 'datainformasi') {
          params.data_informasi_id = this.item_id
        } else if (this.type === 'non-teknis') {
          params.non_teknis_id = this.item_id
        }
      }
      this.getItem(params)
    },
    getItem(params) {
      this.$http.get(`/clearance/${this.targetUrl}`, {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            if (this.type === 'aplikasi' || this.type === 'infrastruktur') {
              [this.dataItem] = res.data.data
            } else {
              this.dataItem = res.data.data
            }
            this.openRekomendasi()
            this.app_view = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    openRekomendasi() {
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_id,
      }
      if (this.dataItem.aplikasi_id) {
        params.type = 'app'
        params.aplikasi_id = this.dataItem.aplikasi_id
      } else if (this.dataItem.infrastruktur_id) {
        params.type = 'infra'
        params.infrastruktur_id = this.dataItem.infrastruktur_id
      } else if (this.dataItem.data_informasi_id) {
        params.type = 'data'
        params.data_informasi_id = this.dataItem.data_informasi_id
      } else if (this.dataItem.non_teknis_id) {
        params.type = 'non-teknis'
        params.non_teknis_id = this.dataItem.non_teknis_id
      }
      this.$http.get('/recommendation/get-rekomendasi-sementara', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.listRekomendasi.continue = []
          }
        })
    },
  },
}
</script>
