<template>
  <b-row>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jenis Penggunaan Server</h5>
      <p>{{ infData.jenis }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Kepemilikan</h5>
      <p>{{ infData.kepemilikan }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Nama Pemilik</h5>
      <p>{{ infData.nama_pemilik }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Unit Pengelola Server</h5>
      <p>{{ infData.unit_operasional }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Lokasi Perangkat Keras Server</h5>
      <p>{{ infData.lokasi }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Perangkat Lunak yang digunakan</h5>
      <p>{{ infData.perangkat_lunak }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Kapasitas memori</h5>
      <p>{{ infData.kapasitas_memori }} GB</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jenis Teknologi Prosesor</h5>
      <p>{{ infData.jenis_prosesor }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Jumlah Kapasitas Penyimpanan</h5>
      <p>{{ infData.kapasitas_penyimpanan }} GB</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Teknik Penyimpanan</h5>
      <p>{{ infData.teknis_penyimpanan }}</p>
    </b-col>
    <b-col
      md="12"
      class="mb-50"
    >
      <h5>Deskripsi Perangkat Keras Server</h5>
      <p>{{ infData.deskripsi }}</p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    infData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
