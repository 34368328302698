<template>
  <b-row>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Bandwidth Internet</h5>
      <p>{{ infData.bandwidth }} MBPS</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Lokasi</h5>
      <p>{{ infData.lokasi }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Kepemilikan</h5>
      <p>{{ infData.kepemilikan }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Unit Kerja Penanggung Jawab</h5>
      <p>{{ infData.unit_kerja }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Klasifikasi Tier Fasilitas</h5>
      <p>{{ infData.tier }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Sistem Pengamanan Fasilitas</h5>
      <p>{{ infData.sistem_pengaman }}</p>
    </b-col>
    <b-col
      md="12"
      class="mb-50"
    >
      <h5>Deskripsi Pusat Data</h5>
      <p>{{ infData.deskripsi }}</p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    infData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
