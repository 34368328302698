var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form-no"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('b-form-radio-group',{staticClass:"verifikasi-kegiatan-radios",attrs:{"options":_vm.options,"button-variant":"outline-secondary","size":"lg","name":"verifikasi","buttons":""},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}})],1)],1),(_vm.mode == 'no')?[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Penjelasan Kegiatan")]),_c('p',[_vm._v("Uraikan secara singkat dan jelas terkait kegiatan")]),_c('b-form-group',{attrs:{"label":"Latar Belakang Pengadaan","label-for":"vi-summary-latar"}},[_c('validation-provider',{attrs:{"name":"Latar Belakang Pengadaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-summary-latar","rows":"5","required":"","name":"latar","placeholder":"Latar Belakang Pengadaan"},model:{value:(_vm.summary.latar),callback:function ($$v) {_vm.$set(_vm.summary, "latar", $$v)},expression:"summary.latar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2204796346)})],1),_c('b-form-group',{attrs:{"label":"Urgensi dan Tujuan Pengadaan","label-for":"vi-summary-urgensi"}},[_c('validation-provider',{attrs:{"name":"Urgensi dan Tujuan Pengadaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-summary-urgensi","rows":"5","required":"","name":"urgensi","placeholder":"Urgensi dan Tujuan Pengadaan"},model:{value:(_vm.summary.urgensi),callback:function ($$v) {_vm.$set(_vm.summary, "urgensi", $$v)},expression:"summary.urgensi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2365512649)})],1),_c('b-form-group',{attrs:{"label":"Kegunaan dan Manfaat Item Pengadaan","label-for":"vi-summary-manfaat"}},[_c('validation-provider',{attrs:{"name":"Kegunaan dan Manfaat Item Pengadaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-summary-manfaat","rows":"5","required":"","name":"manfaat","placeholder":"Kegunaan dan Manfaat Item Pengadaan"},model:{value:(_vm.summary.manfaat),callback:function ($$v) {_vm.$set(_vm.summary, "manfaat", $$v)},expression:"summary.manfaat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2546906193)})],1),_c('b-form-group',{attrs:{"label":"Dan hal lainnya yg perlu dijelaskan (contoh : kondisi existing, amanat hukum terkait, hasil koordinasi) ","label-for":"vi-summary-lainnya"}},[_c('validation-provider',{attrs:{"name":"Dan hal lainnya yg perlu dijelaskan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-summary-lainnya","rows":"5","required":"","name":"lainnya","placeholder":"Dan hal lainnya yg perlu dijelaskan (contoh : kondisi existing, amanat hukum terkait, hasil koordinasi) "},model:{value:(_vm.summary.lainnya),callback:function ($$v) {_vm.$set(_vm.summary, "lainnya", $$v)},expression:"summary.lainnya"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,281906884)})],1)],1),(_vm.detailData.jenis_pengadaan_id === 6)?_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Spesifikasi Teknis")]),_c('p',[_vm._v("Uraikan secara singkat dan jelas terkait Spesifikasi Teknis Belanja Data untuk masing-masing jenis belanja Data")]),_c('b-form-group',{attrs:{"label":"Mekanisme Pengumpulan Data","label-for":"vi-teknis-fungsi"}},[_c('validation-provider',{attrs:{"name":"Mekanisme Pengumpulan Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-teknis-fungsi","rows":"5","required":"","name":"fungsi","placeholder":"Mekanisme Pengumpulan Data"},model:{value:(_vm.teknis.fungsi),callback:function ($$v) {_vm.$set(_vm.teknis, "fungsi", $$v)},expression:"teknis.fungsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3820277960)})],1),_c('b-form-group',{attrs:{"label":"Penerapan Prinsip SDI Dalam Data","label-for":"vi-teknis-layanan"}},[_c('validation-provider',{attrs:{"name":"Penerapan Prinsip SDI Dalam Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-teknis-layanan","rows":"5","required":"","name":"layanan","placeholder":"Penerapan Prinsip SDI Dalam Data"},model:{value:(_vm.teknis.layanan),callback:function ($$v) {_vm.$set(_vm.teknis, "layanan", $$v)},expression:"teknis.layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,956970918)})],1),_c('b-form-group',{attrs:{"label":"Potensi K/L Yang Memproduksi Data","label-for":"vi-teknis-spek"}},[_c('validation-provider',{attrs:{"name":"Potensi K/L Yang Memproduksi Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-teknis-spek","rows":"5","required":"","name":"spek","placeholder":"Potensi K/L Yang Memproduksi Data"},model:{value:(_vm.teknis.spek),callback:function ($$v) {_vm.$set(_vm.teknis, "spek", $$v)},expression:"teknis.spek"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2866749298)})],1)],1):_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Penjelasan Teknis")]),_c('p',[_vm._v("Uraikan secara singkat dan jelas terkait Spesifikasi Teknis Belanja ")]),_c('b-form-group',{attrs:{"label":"Fungsi Utama","label-for":"vi-teknis-fungsi"}},[_c('validation-provider',{attrs:{"name":"Fungsi Utama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-teknis-fungsi","rows":"5","required":"","name":"fungsi","placeholder":"Fungsi Utama"},model:{value:(_vm.teknis.fungsi),callback:function ($$v) {_vm.$set(_vm.teknis, "fungsi", $$v)},expression:"teknis.fungsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2929902920)})],1),_c('b-form-group',{attrs:{"label":"Layanan Yang Didukung","label-for":"vi-teknis-layanan"}},[_c('validation-provider',{attrs:{"name":"Layanan Yang Didukung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-teknis-layanan","rows":"5","required":"","name":"layanan","placeholder":"Layanan Yang Didukung"},model:{value:(_vm.teknis.layanan),callback:function ($$v) {_vm.$set(_vm.teknis, "layanan", $$v)},expression:"teknis.layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2807595006)})],1),_c('b-form-group',{attrs:{"label":"Spesifikasi Teknis Item Pengadaan","label-for":"vi-teknis-spek"}},[_c('p',[_vm._v("(Meliputi jumlah unit, type, platform, procesor, storage, memory, warranty, license, dst)")]),_c('validation-provider',{attrs:{"name":"Spesifikasi Teknis Item Pengadaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-teknis-spek","rows":"5","required":"","name":"spek","placeholder":"Spesifikasi Teknis Item Pengadaan"},model:{value:(_vm.teknis.spek),callback:function ($$v) {_vm.$set(_vm.teknis, "spek", $$v)},expression:"teknis.spek"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2028263142)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Dokumen Pendukung","label-for":"file_tambahan"}},[_c('b-form-file',{staticClass:"mt-1",attrs:{"name":"file_tambahan"},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}})],1)],1)]:_vm._e(),(_vm.errorStat)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" Error Found: "+_vm._s(_vm.errorMsg)+" ")])])],1):_vm._e(),(_vm.mode)?_c('b-col',[_c('b-button',{staticClass:"mt-0 mt-md-1",attrs:{"variant":"outline-success","size":"sm"},on:{"click":_vm.checkSave}},[_c('span',[_vm._v("Kirim Konfirmasi")])]),(_vm.dataSaved)?_c('b-button',{staticClass:"mt-0 mt-md-2 ml-50",attrs:{"variant":"flat-success","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"14"}}),_vm._v(" Data Konfirmasi Berhasil Disimpan ")],1):_vm._e()],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }