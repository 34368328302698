<template>
  <b-row>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Data Yang Disimpan</h5>
      <p>{{ infData.data_tematik }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Kepemilikan</h5>
      <p>{{ infData.kepemilikan }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Nama Pemilik</h5>
      <p>{{ infData.nama_pemilik }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Unit Pengelola Perangkat Keras Media Penyimpanan</h5>
      <p>{{ infData.unit_operasional }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Lokasi Perangkat Keras Media Penyimpanan</h5>
      <p>{{ infData.lokasi }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Perangkat Lunak yang digunakan</h5>
      <p>{{ infData.perangkat_lunak }}</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Kapasitas Penyimpanan</h5>
      <p>{{ infData.kapasitas_penyimpanan }} GB</p>
    </b-col>
    <b-col
      md="6"
      class="mb-50"
    >
      <h5>Metode Akses Data Sharing</h5>
      <p>{{ infData.metode_akses_penyimpanan }}</p>
    </b-col>
    <b-col
      md="12"
      class="mb-50"
    >
      <h5>Deskripsi Data Storage</h5>
      <p>{{ infData.deskripsi }}</p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    infData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
