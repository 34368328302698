<template>
  <div>
    <b-row class="pt-1">
      <b-col
        md="4"
        class="mb-50"
      >
        <h5>Domain Infrastruktur</h5>
        <p>{{ dataInfra.domain_infrastruktur }}</p>
      </b-col>
      <b-col
        md="4"
        class="mb-50"
      >
        <h5>Area Infrastruktur</h5>
        <p>{{ dataInfra.area_infrastruktur }}</p>
      </b-col>
      <b-col
        md="4"
        class="mb-50"
      >
        <h5>Kategori Infrastruktur</h5>
        <p>{{ (dataInfra.kategori_infra !== '') ? dataInfra.kategori_infra : '-' }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>{{ `Nama ${nama_infra}` }}</h5>
        <p>{{ dataInfra.nama }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>STATUS KEGIATAN</h5>
        <p>{{ dataInfra.status_kegiatan }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TOTAL ANGGARAN</h5>
        <p>{{ dataInfra.total_anggaran ? Number(dataInfra.total_anggaran).toLocaleString() : 0 }}</p>
      </b-col>
      <b-col
        v-if="dataInfra.sumber_dana"
        md="6"
        class="mb-50"
      >
        <h5>SUMBER DANA</h5>
        <p>{{ dataInfra.sumber_dana }}</p>
      </b-col>
      <b-col
        v-if="dataInfra.jenis_belanja"
        md="6"
        class="mb-50"
      >
        <h5>JENIS BELANJA</h5>
        <p>{{ dataInfra.jenis_belanja }}</p>
      </b-col>
      <b-col
        v-if="dataInfra.jenis_pengadaan"
        md="6"
        class="mb-50"
      >
        <h5>JENIS PENGADAAN</h5>
        <p>{{ dataInfra.jenis_pengadaan }}</p>
      </b-col>
    </b-row>

    <template v-if="dataInfra.domain_infra_id === 3 && dataInfra.area_infra_id === 1">
      <!-- Type Sistem Integrasi : Jaringan Intra Pemerintah -->
      <infra-sis-jar
        ref="sisJar"
        :inf-data="dataInfra"
      />
    </template>

    <template v-else-if="dataInfra.domain_infra_id === 3 && dataInfra.area_infra_id === 7">
      <!-- Type Sistem Integrasi : Sistem Penghubung Layanan Pemerintah -->
      <infra-sis-hub
        ref="sisJar"
        :inf-data="dataInfra"
      />
    </template>

    <template v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 3">
      <!-- Type Platform : Komputasi Awan -->
      <infra-plat-cloud
        ref="platCloud"
        :inf-data="dataInfra"
      />
    </template>

    <template
      v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 8"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Software -->
      <infra-plat-kia-soft
        ref="platKiaSoft"
        :inf-data="dataInfra"
      />
    </template>

    <template
      v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 7"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Server -->
      <infra-plat-kia-server
        ref="platKiaServer"
        :inf-data="dataInfra"
      />
    </template>

    <template
      v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 5"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Media Penyimpanan -->
      <infra-plat-kia-storage
        ref="platKiaStorage"
        :inf-data="dataInfra"
      />
    </template>

    <template
      v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 3"
    >
      <infra-plat-kia-network
        ref="platKiaNetwork"
        :inf-data="dataInfra"
      />
    </template>

    <template
      v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 4"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Keamanan -->
      <infra-plat-kia-security
        ref="platKiaSecurity"
        :inf-data="dataInfra"
      />
    </template>

    <template
      v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 6"
    >
      <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Periferal -->
      <infra-plat-kia-periferal
        ref="platKiaPeriferal"
        :inf-data="dataInfra"
      />
    </template>

    <!-- Type Fasilitas Komputasi : ALL -->
    <template v-else>
      <infra-fasil
        ref="fasil"
        :inf-data="dataInfra"
      />
    </template>

    <b-row>
      <b-col
        v-if="dataInfra.kak_uri !== null"
        md="6"
        class="mb-50"
      >
        <h5>Kerangka Acuan Kerja</h5>
        <p>
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="openKomponen(dataInfra.kak_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(dataInfra.kak_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <small>{{ dataInfra.kak_uri.substring(dataInfra.kak_uri.lastIndexOf('/') + 1) }}</small>
        </p>
      </b-col>
      <b-col
        v-if="dataInfra.additional_docs.length !== 0"
        md="6"
        class="mb-50"
      >
        <h5>Dokumen Tambahan</h5>
        <p
          v-for="(data, index) in dataInfra.additional_docs"
          :key="index"
        >
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="openKomponen(data.document_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(data.document_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <small>{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}</small>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'

import InfraFasil from '../template-detail-infra/InfraFasil.vue'
import InfraSisJar from '../template-detail-infra/InfraSisJar.vue'
import InfraSisHub from '../template-detail-infra/InfraSisHub.vue'
import InfraPlatCloud from '../template-detail-infra/InfraPlatCloud.vue'
import InfraPlatKiaSoft from '../template-detail-infra/InfraPlatKiaSoft.vue'
import InfraPlatKiaServer from '../template-detail-infra/InfraPlatKiaServer.vue'
import InfraPlatKiaStorage from '../template-detail-infra/InfraPlatKiaStorage.vue'
import InfraPlatKiaNetwork from '../template-detail-infra/InfraPlatKiaNetwork.vue'
import InfraPlatKiaSecurity from '../template-detail-infra/InfraPlatKiaSecurity.vue'
import InfraPlatKiaPeriferal from '../template-detail-infra/InfraPlatKiaPeriferal.vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BLink,

    InfraFasil,
    InfraSisJar,
    InfraSisHub,
    InfraPlatCloud,
    InfraPlatKiaSoft,
    InfraPlatKiaServer,
    InfraPlatKiaStorage,
    InfraPlatKiaNetwork,
    InfraPlatKiaSecurity,
    InfraPlatKiaPeriferal,
  },
  props: {
    dataInfra: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      nama_infra: '',
    }
  },
  mounted() {
    this.nama_infra = this.dataInfra.area_infrastruktur
    if (this.dataInfra.kategori_infra !== '') {
      this.nama_infra = this.dataInfra.kategori_infra
    }
  },
  methods: {
    openKomponen(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
  },
}
</script>
